import { Component, OnInit } from '@angular/core';
import { APIURLHelperService } from '../apiurlhelper.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public quoteModel: any = {};
  public quoteSubmitted = false;

  constructor(private http: HttpClient, private APIURLService: APIURLHelperService) { 
    this.quoteModel.Name = "";
    this.quoteModel.ProjectDesc = "";
    this.quoteModel.Email = "";
  }

  ngOnInit() {
  }

  onSubmit() {
    const body = JSON.stringify(this.quoteModel);
    console.log(body);
    var config = {headers : {
      "Content-Type": "application/json"
    }
    }; 
    this.http.post(this.APIURLService.getSubmitQuotaURL(), body, config)
    .subscribe(
      (res) => {
        console.log(res);
        if(res === true) {
          this.quoteSubmitted = true;
        }
      },
      err => {
      console.log(err);
      //finish loading
      }
    );
  }

}
