import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  private lastY = 0;
  private forwardCount = 0;
  private backwardCount = 0;
  public shownNavMenu = false;
  constructor() { }
  
  ngOnInit() {

  }

  ngAfterViewInit() {
    var navbar = document.getElementById("navibar-hide");
    navbar.classList.add("animated");
    navbar.classList.add("fadeInDown");
    navbar.id = "navibar";
  }

  toggleNavMenu() {
    if(this.shownNavMenu == false) {
      this.shownNavMenu = true;
    }
    else {
      this.shownNavMenu = false;
    }
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    console.log(this.backwardCount);
    if(window.pageYOffset == 0) {
      this.showNavbar();
    }
    if(window.pageYOffset >this.lastY) {
      this.forwardCount++;
      this.backwardCount = 0;
    }
    else if(window.pageYOffset < this.lastY) {
        this.backwardCount++;
        this.forwardCount = 0;
    }
    if(this.forwardCount > 25) {
      this.hideNavbar();
    }
    if(this.backwardCount > 1) {
      this.showNavbar();
    }
    this.lastY = window.pageYOffset;
  }

  hideNavbar() {
    var navbar = document.getElementById("navibar");
    if(navbar == null) {
      navbar = document.getElementById("navibar-hide");
    }
    navbar.classList.remove("fadeInDown");
    navbar.classList.add("fadeOutUp");
    navbar.id = "navibar-hide";
  }
  showNavbar() {
    var navbar = document.getElementById("navibar-hide");
    if(navbar == null) {
      navbar = document.getElementById("navibar");
    }
    navbar.classList.remove("fadeOutUp");
    navbar.classList.add("fadeInDown");
    navbar.id = "navibar";
  }
  
  scroll(elementName) {
    var element = document.getElementById(elementName);
    element.scrollIntoView({behavior: "smooth"});
  }

}
