import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(private http: HttpClient) { }
  public projects;
  public getProjectsAPIURL = "https://api.ryandevkit.ca/api/devkit/getprojects";
  ngOnInit() {
    this.getProjects();
  }

  getProjects() {
    let data = {};
      const body = JSON.stringify(data);
      var config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
      }
      }; 
      this.http.get(this.getProjectsAPIURL)
      .subscribe(
      (res) => {
        console.log(res);
        this.projects = res;
      },
      err => {
      console.log(err);
      //finish loading
      }
      );
  }
}
