import { Component, OnInit } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  ngOnInit() {
  }

}
