import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class APIURLHelperService {
  private baseURL = "http://167.88.124.165:5001/api/devkit/";
  private submitQuotaEmailURL = "SubmitQuotaEmail";
  private getWorkExpAPIURL = "GetWorkExperience";
  private getEducationAPIURL = "GetEducation";
  constructor() { }

  getSubmitQuotaURL() {
    return this.baseURL + this.submitQuotaEmailURL;
  }
}
