import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  public workExp;
  public education;
  // public getWorkExpAPIURL = "https://ryandevkitapi.azurewebsites.net/api/devkitapi/GetWorkExperience";
  // public getEducationAPIURL = "https://ryandevkitapi.azurewebsites.net/api/devkitapi/GetEducation";
  public getWorkExpAPIURL = "https://api.ryandevkit.ca/api/devkit/GetWorkExperience";
  public getEducationAPIURL = "https://api.ryandevkit.ca/api/devkit/GetEducation";
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getWorkExp();
    this.getEducation();
  }

  getWorkExp() {
    let data = {};
      const body = JSON.stringify(data);
      var config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
      }
      }; 
      this.http.get(this.getWorkExpAPIURL)
      .subscribe(
      (res) => {
        console.log(res);
        this.workExp = res;
      },
      err => {
      console.log(err);
      //finish loading
      }
      );
  }
  getEducation() {
    let data = {};
      const body = JSON.stringify(data);
      var config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
      }
      }; 
      this.http.get(this.getEducationAPIURL)
      .subscribe(
      (res) => {
        console.log(res);
        this.education = res;
      },
      err => {
      console.log(err);
      //finish loading
      }
      );
  }

}
